.bg-tags {
  background-color: #aaaaaa;
  color: #fff;
  font-size: 14px;
}

.description {
  resize: none;
  
}
