// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/bootstrap";

html {
  overflow-y: auto;
  overflow-x: hidden;
}

body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div:where(.swal2-container) {
  display: grid;
  position: fixed;
  z-index: 10600 !important;
  inset: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(
      min-content,
      auto
    );
  height: 100%;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}

.swal2-spinner {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
  box-sizing: border-box;
  border: 4px solid #f3f3f3;
  border-left: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.custom-modal-backdrop {
  z-index: 1201 !important; // Adjust this value as needed
}

#tabTable {
  .css-89lunj-MuiPaper-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    box-shadow: none;
    border-radius: 20px;
    border-top-right-radius: 0px !important;
  }
  
}

.tss-1fz5efq-MUIDataTableToolbar-left {

  display: none;
}
