.plus-sign{
  z-index: 100;
  cursor: pointer;
  opacity: 1;
  transform: translateX(-50%);
}

.sponsor-container:hover{
  & .plus-sign{
    //opacity: 1;
  }
}